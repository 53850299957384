import { Badge, NewJPButton, Text5XL } from '../GeneralComponents';
import { SectionContent } from '../OldGeneralComponents';
import { useRouter } from 'next/router';
import Image from 'next/image';
import locale from '../../locales/en/NativeLanding';
import parse from 'html-react-parser';
import useSectionContentWidth from '../../hooks/useSectionContentWidth';
const FeaturesDetails = () => {
  const { sectionWidthInPixels } = useSectionContentWidth();
  return (
    <SectionContent maxWidth={sectionWidthInPixels} className="py-[90px]">
      <h1 className="mx-auto mb-2 text-center text-[30px] font-500 leading-[44px] sm:text-[34px] lg:text-[36px]">
        {locale.landing.features_summary_header}
      </h1>
      <h2 className="mx-auto mb-4 text-center text-base leading-[32px] text-grey-600 sm:text-[20px] lg:text-[20px]">
        Our AI model find the right B-roll for the right moment
      </h2>
      <div className="relative mt-[48px] flex w-full flex-col items-center justify-center">
        {locale.landing.features_summary.map((feature, index) => {
          return (
            <div
              key={feature.header}
              className={`mb-[32px] flex w-full flex-col items-center justify-center lg:flex-row lg:flex-row`}
            >
              <Image
                src={feature.image_url}
                width={324}
                height={212}
                alt={feature.header}
                className="flex lg:mr-1"
              />

              <div
                className={`flex w-full flex-col px-3 text-left md:text-center lg:ml-[38px] lg:max-w-[400px] lg:px-0 lg:text-left xl:max-w-[480px]`}
              >
                {!feature.isReady && (
                  <Badge
                    text={'Coming Soon'}
                    colorType="light-blue"
                    className="mx-auto mb-3 !w-[100px] !text-xs md:ml-0 md:mr-auto"
                  />
                )}
                <div className="mb-2 mt-2 text-center text-[26px] font-500 font-medium leading-[38px] sm:text-[28px] lg:mb-[4px] lg:text-left lg:text-[26px]">
                  {parse(feature.header)}
                </div>
                <div className="text-center text-[20px] font-normal text-gray-500 sm:text-[22px] lg:text-left lg:text-[20px]">
                  {feature.subheader}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </SectionContent>
  );
};

export default FeaturesDetails;
