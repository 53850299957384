import { Text2XL, Text4XL, Text5XL, TextXL } from '../GeneralComponents';
import { useMemo } from 'react';
import Image from 'next/image';
import UseWindowSize from '@/utils/useWindowSize';
import cubes from '../../assets/images/icons/cubes.svg';
import locale from '../../locales/en/NativeLanding';
const reviewers: {
  [key: string]: {
    name: string;
    image: string;
    review: string;
    desc: string;
  };
} = {
  bryan_guerra: {
    name: 'Bryan Guerra',
    desc: 'Ecommerce Coach, 78k YouTube Subscribers',
    review:
      "“Jupitrr AI simplifies the process of making professional quality videos and makes it quick and easy. It is perfect for the people who want to make money from videos but don't want to get in front of the camera.”",
    image: 'bryan.png'
  },
  jordan_wilson: {
    name: 'Jordan Wilson',
    desc: 'AI Expert & Podcaster, 7.25k YouTube (Everyday AI)',
    review:
      "“if you're a content creator like me or a small business owner, putting out training or educational videos, you don't just want it to be a talking head. Jupitrr AI let you highlight different things in your video and create some b-roll for it.”",
    image: 'jordan.png'
  },
  novie_dizon: {
    name: 'Novie Dizon',
    desc: 'Finance Coach, 58k TikTok Followers',
    review:
      '“Jupitrr AI is a real time-saver for content creators like me, helping us find the perfect B-roll effortlessly! I absolutely love it!”',
    image: 'novie.png'
  },
  jay_kapoor: {
    name: 'Jay Kapoor',
    desc: 'Tech Creator, 600k Instagram Followers',
    review:
      '“Jupitrr AI is very useful and one of its kind tools for creators, especially podcasters. It saves time and resources to convert your audio recordings into beautiful presentable videos which you can share on your social media.”',
    image: 'jay.png'
  }
};

const ReviewCard = ({
  name,
  image,
  review,
  desc,
  index
}: {
  name?: string;
  image?: string;
  review?: string;
  desc?: string;
  index?: number;
}) => {
  return (
    <div
      key={index}
      className={`flex h-[300px] w-[297px] min-w-[297px] flex-col rounded-lg bg-white px-5 py-4 shadow-sm lg:w-[297px]`}
    >
      <div className="flex flex-row items-center">
        <Image
          src={`/index/reviewers/${image}`}
          alt={name}
          width={60}
          height={60}
          className="mr-3"
        />
        <div className="flex flex-col">
          <div className="font-600">{name}</div>
          <div className="text-[13px] leading-tight text-grey-500">{desc}</div>
        </div>
      </div>
      <div className="mt-[16px] h-auto w-full text-[16px] leading-normal text-grey-600">
        {review}
      </div>
    </div>
  );
};

const numberOfCardsPerRow = 4;

const WhatCreatorsSay = () => {
  const { width } = UseWindowSize();
  const heightClass = 'flex w-full items-center justify-center';

  const firstRowData = useMemo(() => {
    return Object.keys(reviewers)
      .filter((r, i) => i < numberOfCardsPerRow)
      .map((key, index) => {
        const { name, image, review, desc } = reviewers[key];
        return (
          <ReviewCard
            key={index}
            name={name}
            image={image}
            review={review}
            desc={desc}
          />
        );
      });
  }, [reviewers, width]);

  // 'flex h-[260px] w-full items-center justify-center lg:h-[450px] min-[1680px]:h-[540px] ';
  return (
    <div
      className={`relative bg-blue-600 py-[64px] ${heightClass}`}
      style={{
        backgroundImage: `url(${cubes.src})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: '0 -30px',
        backgroundSize: 'auto' // Adjust the size as needed
      }}
    >
      <div className="z-20 flex w-full flex-col items-center">
        <div className="m-auto  mb-[70px]  max-w-screen-lg px-3 text-center md:px-0">
          <Text4XL
            className="mb-2 font-300 text-white"
            style={{
              fontSize: '36px',
              lineHeight: '44px',
              fontWeight: '300',
              fontStyle: 'normal'
            }}
          >
            {locale.what_creators_say.header}
          </Text4XL>
          <Text2XL
            className=" text-white"
            style={{
              fontSize: '20px',
              lineHeight: '32px',
              fontStyle: 'normal'
            }}
          >
            {locale.what_creators_say.subheader}
          </Text2XL>
        </div>
        <div className="m-auto flex w-full max-w-screen-2xl flex-col items-start overflow-hidden min-[1260px]:justify-center ">
          <div className="no-scrollbar flex w-full flex-row gap-4 overflow-x-scroll px-3 min-[1260px]:justify-center">
            {firstRowData}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatCreatorsSay;
