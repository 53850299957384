import { MAX_INT32, formatNumber } from '@/utils/generalUtilities';
import { UserInfo } from '../../models/User';
import {
  findAvailableVideoLengthByProductName,
  findMaxSlideshowLengthByProductName,
  findMaxVideoLengthByProductName,
  findPlanNameByAppSumoProductId,
  findPlanNameByProductId
} from '../../utils/premium';
import { gaPremiumPurchase } from '../../lib/GoogleAnalytics';
import { useRouter } from 'next/router';
import Email from '../../models/Email';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
const mic = require('../../assets/images/landing/mic.svg');
const slideshow = require('../../assets/images/landing/slideshow.svg');
import { UpgradeSubscriptionModal } from '../Modals/UpgradeSubscriptionModal';
import { toast } from 'react-toastify';
import { useModal } from '@/hooks/useModal';
import UpgradeHomePageButton from '../../assets/images/UpgradeHomePageButton.svg';

export const PlanInformationPill: React.FC<{ user: any }> = ({ user }) => {
  const premium = user?.current_premium;
  const permission = user?.premium_permissions;
  const router = useRouter();
  const [planName, setPlanName] = useState('unknown');
  const [audiogramCredits, setAudiogramCredits] = useState<
    number | 'unlimited'
  >(0);
  const [slideshowCredits, setSlideshowCredits] = useState<
    number | 'unlimited'
  >(0);

  const { openModal, closeModal } = useModal();
  const justPaid = () => {
    if (
      router.query &&
      router.query.payment &&
      router.query.payment === 'success'
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (router.query.plan === 'getty')
      toast.success('Lifetime access getty purchased successfully');
  }, [router.query]);

  useEffect(() => {
    /* setup tracking for paid plans */
    const justPaidSubscription = justPaid() && router.query.plan !== 'getty';
    if (justPaid() && justPaidSubscription) {
      const paidPlan = router.query.plan as string;
      Email.sendPurchaseSuccessEmail(user);
      gaPremiumPurchase(
        paidPlan,
        premium?.source === 'stripe' && premium.id ? premium.id : null
      );
      return;
    }
  }, [premium, router.query]);

  useEffect(() => {
    const justPaidSubscription = justPaid() && router.query.plan !== 'getty';
    const paidPlan = router.query.plan as string;
    if (justPaid() && justPaidSubscription) {
      setPlanName(paidPlan);
      setSlideshowCredits(
        findMaxSlideshowLengthByProductName(paidPlan.toLowerCase()) / 60
      );
      setAudiogramCredits(
        findMaxVideoLengthByProductName(paidPlan.toLowerCase()) === 'unlimited'
          ? 'unlimited'
          : (findMaxVideoLengthByProductName(
              paidPlan.toLowerCase()
            ) as number) / 60
      );
      return;
    }
    if (premium) {
      if (
        premium.source === 'appsumo' ||
        ['jupitrr_tier1', 'jupitrr_tier2', 'jupitrr_tier3'].includes(
          premium.plan_name
        )
      ) {
        setPlanName(findPlanNameByAppSumoProductId(premium.plan_name));
      } else {
        setPlanName(findPlanNameByProductId(premium.product_id));
      }
    } else {
      setPlanName('free');
    }
    if (permission) {
      if (permission.remaining_video_seconds) {
        permission.total_video_seconds === MAX_INT32
          ? setAudiogramCredits('unlimited')
          : setAudiogramCredits(
              findAvailableVideoLengthByProductName(
                Math.floor(permission.remaining_video_seconds / 60),
                premium?.plan_name
              )
            );
      }
      if (permission.remaining_transcription_seconds) {
        setSlideshowCredits(
          Math.floor(permission.remaining_transcription_seconds / 60)
        );
      }
    } else {
      setAudiogramCredits(30);
      setSlideshowCredits(10);
    }
  }, [permission, premium]);

  const audiogramMins = findAvailableVideoLengthByProductName(
    Math.floor(permission?.remaining_video_seconds / 60),
    premium?.plan_name
  );
  const audiogramMinsString =
    typeof audiogramMins === 'number' ? `${audiogramMins} mins` : audiogramMins;

  return (
    <div
      style={{
        backgroundColor: 'rgba(249, 250, 251, 1)'
      }}
      className="flex flex-col gap-[10px] rounded-lg border border-solid border-gray-200 px-4 py-[10px]"
    >
      <div className="flex flex-col">
        <div className="text-xs font-500 text-grey-400">Plan</div>
        <p className="text-base font-700 capitalize text-grey-700">
          {planName}
        </p>
      </div>

      <div className="flex flex-row">
        <div className="flex items-center">
          <Image alt="slideshow" loading={'lazy'} src={slideshow} />
        </div>
        <div className="ml-3 flex flex-col self-center text-left">
          <p className="text-base font-700 capitalize text-grey-700">
            {formatNumber(slideshowCredits)}{' '}
            {permission?.total_transcription_seconds && (
              <span className="text-xs text-gray-400">
                / {formatNumber(permission?.total_transcription_seconds / 60)}{' '}
                mins
              </span>
            )}
          </p>
          <div className="text-xs font-500 text-grey-400">AI video</div>
        </div>
      </div>

      <div className="flex flex-row">
        <div className="flex items-center">
          <Image alt="mic" loading={'lazy'} src={mic} />
        </div>
        <div className="ml-3 flex flex-col self-center text-left text-base">
          <p className="text-base font-700 capitalize text-grey-700">
            {audiogramCredits === 'unlimited' ? (
              'Unlimited'
            ) : (
              <>
                {formatNumber(audiogramCredits)}{' '}
                <span className="text-xs text-gray-400">
                  /{' '}
                  {permission?.total_video_seconds === MAX_INT32
                    ? 'Unlimited'
                    : audiogramMinsString}
                </span>
              </>
            )}
          </p>
          <div className="text-xs font-bold text-grey-400">Audiogram video</div>
        </div>
      </div>
      {planName !== 'pro' ? (
        <div className="mt-2 flex flex-row gap-3">
          <button
            onClick={() => {
              planName === 'free'
                ? openModal(<UpgradeSubscriptionModal />, {
                    width: 400,
                    shouldCloseOnOverlayClick: true
                  })
                : router.push('/pricing', '_blank');
            }}
          >
            <Image
              src={UpgradeHomePageButton}
              alt="updgrade subscription home page button"
            />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
