import {
  Badge,
  NewH2,
  NewH4,
  NewP1,
  NewP2,
  Text3XL,
  Text4XL,
  Text5XL,
  TextXL
} from '../GeneralComponents';
import { SectionContent } from '../OldGeneralComponents';
import Image from 'next/image';
import VideoGif from './VideoGif';
import locale from '../../locales/en/NativeLanding';
import parse from 'html-react-parser';
const MoreFeatures = () => {
  return (
    <SectionContent className="mt-[64px]" maxWidth={'1024px'}>
      <h1 className="mx-auto mb-[16px] text-center text-[30px] font-500 leading-[44px] sm:text-[34px] lg:text-[36px]">
        {locale.landing.more_features_header}
      </h1>
      <div className="mx-auto mb-[64px] mt-[64px] grid w-full justify-center gap-8 md:mx-auto lg:grid-cols-2">
        {locale.landing.more_features.map((tutorial) => {
          return (
            <div
              key={tutorial.header}
              className="flex h-auto flex-col rounded-lg border-2 border-solid border-gray-200"
            >
              <div className="relative flex flex-col px-8 py-[16px]">
                {!tutorial?.isReady ? (
                  <div className="absolute -top-[40px] md:-top-[49px]">
                    <Badge
                      text={'Coming Soon'}
                      colorType="light-blue"
                      className="!w-[100px] !text-xs"
                    />
                  </div>
                ) : null}
                <h1 className="mb-4 mt-2 text-left text-[26px] font-500 font-bold leading-[44px] sm:text-[34px] lg:text-[30px] lg:text-[36px] xl:mx-auto">
                  {parse(tutorial.header)}
                </h1>
                <TextXL
                  className="text-grey-500"
                  style={{
                    fontSize: '18px',
                    lineHeight: '30px',
                    fontWeight: '250',
                    fontStyle: 'bold'
                  }}
                >
                  {parse(tutorial.subheader)}
                </TextXL>
              </div>
              <div className="mt-2 mb-6 w-full px-8 md:px-8 lg:px-0">
                <Image
                  src={tutorial.image_url}
                  alt={tutorial.header}
                  className="mx-auto rounded-2xl bg-grey-50"
                />
              </div>
            </div>
          );
        })}
      </div>
    </SectionContent>
  );
};

export default MoreFeatures;
