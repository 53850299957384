import { API_URL } from './constant';
import { Audio } from './Audio';
import { LocalStorageItems, getItem } from '@/utils/generalUtilities';
import Stripe from 'stripe';
import axios, { AxiosResponse } from 'axios';
import fetchWrapper, {
  FetchConfig,
  FetchError,
  MethodType
} from '../utils/fetchWrapper';

export type premiumPermission = {
  total_video_seconds: number;
  remaining_video_seconds: number;
  total_transcription_seconds?: number;
  remaining_transcription_seconds?: number;
  last_updated: Date;
  cycle_start_date: Date;
};

type AppsumoTiers = 'jupitrr_tier1' | 'jupitrr_tier2' | 'jupitrr_tier3';

export type premiumMongo = premiumStripeMongo | premiumAppsumoMongo;

export type premiumAppsumoMongo = {
  source: 'appsumo';
  user_id: string;
  price_id: AppsumoTiers;
  product_id: AppsumoTiers;
  plan_name: AppsumoTiers;
  last_updated: Date;
  invoice_item_uuid?: string;
  has_getty?: boolean;
};

type premiumStripeMongo = (Stripe.Subscription | Stripe.Checkout.Session) & {
  source?: 'stripe';
  user_id: string;
  price_id: string;
  product_id: string;
  plan_name: string | null;
  last_updated: Date;
};

export interface UserInfo {
  subscription: any;
  id: string;
  url_slug: string;
  email: string;
  display_name: string;
  avatar: string;
  bio?: string;
  audios: Audio[];
  premium_permissions?: premiumPermission;
  current_premium?: premiumMongo;
  stripe_customer_id?: string;
  appsumo_license_uuid?: string;
  created_at: string;
  last_updated: string;
  onboarding?: {
    role: string;
    frequency: string;
    channel: string;
  };
}

const User = {
  name: '/users',
  route: '',
  async createUser(token: string): Promise<UserInfo> {
    this.route = `${API_URL + this.name}`;
    const data = await fetchWrapper(this.route, MethodType.POST, null, token);
    return data as UserInfo;
  },
  async getUserInfo(
    uid_or_url_slug: string,
    token?: string
  ): Promise<UserInfo> {
    this.route = `${API_URL + this.name}/${uid_or_url_slug}`;
    const data = await fetchWrapper(this.route, MethodType.GET, null, token);
    return data as UserInfo;
  },

  me(token: string, fetchConfig?: FetchConfig): Promise<UserInfo & FetchError> {
    // UserInfo
    return new Promise((resolve, reject) => {
      this.route = `${API_URL}/me`;
      fetchWrapper(this.route, MethodType.GET, null, token, fetchConfig)
        .then((json: any) => {
          resolve(json);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  },

  async getStripeBillingPortal(
    userId: string,
    type: 'cancel' | 'default' = 'default'
  ): Promise<{ url: string }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    this.route = `${
      API_URL + this.name
    }/${userId}/premium-portal-session?type=${type}`;
    const data = await fetchWrapper(this.route, MethodType.GET, null, token);
    return data as { url: string };
  },

  async getStripeCheckoutSessionWithDiscountCode(
    planName: string,
    isAnnual: 'annually' | 'monthly' | 'oneoff'
  ): Promise<{ session_url: string }> {
    const stripeResponse = (await fetchWrapper(
      `${
        API_URL + this.name
      }/create-checkout-session?planName=${planName}&interval=${isAnnual}`,
      MethodType.GET,
      null,
      localStorage.getItem(LocalStorageItems.ACCESS_TOKEN)
    )) as { session_url: string };

    return stripeResponse;
  },

  async update(uid: string, data: any): Promise<UserInfo> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    this.route = `${API_URL + this.name}/${uid}`;
    const body = JSON.stringify(data);
    const res = await fetchWrapper(this.route, MethodType.PATCH, body, token);
    return res as UserInfo;
  },

  async delete(uid: string): Promise<any> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    this.route = `${API_URL + this.name}/?id[]=${uid}`;
    const res = await fetchWrapper(this.route, MethodType.DELETE, null, token);
    return res;
  },

  async resetVideoPermission(
    uid: string,
    videoId: string,
    renderId: string
  ): Promise<{ success: boolean }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    this.route = renderId
      ? `${
          API_URL + this.name
        }/${uid}/reset-video-permissions/${videoId}--${renderId}`
      : `${API_URL + this.name}/${uid}/reset-video-permissions/${videoId}`;
    const res = await fetchWrapper(
      this.route,
      MethodType.PATCH,
      undefined,
      token
    );
    return res as { success: boolean };
  }
};

export default User;
