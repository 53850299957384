import { PageNames } from '../../utils/generalUtilities';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { logoHorizontal, logoWithName } from '../../assets/generalAssets';
import { useRouter } from 'next/router';
import Image from 'next/image';
import classnames from 'classnames';
const Logo = ({
  withName,
  clickable = true
}: {
  withName?: boolean;
  clickable?: boolean;
}) => {
  const screenType = useScreenType();
  const router = useRouter();
  return (
    <div
      onClick={() => clickable && router.push(PageNames.ROOT)}
      className={classnames(
        'relative mr-[14px] flex h-9 items-center justify-center',
        {
          'cursor-pointer': clickable,
          'mt-[1px]': screenType === ScreenTypes.tab
        }
      )}
    >
      <Image
        priority
        onClick={() => clickable && router.push(PageNames.ROOT)}
        src={withName ? logoWithName : logoHorizontal}
        className={`h-full w-full`}
        alt={'jupitrr logo'}
      />
    </div>
  );
};

export default Logo;
