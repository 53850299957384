import { NewJPButton } from '../GeneralComponents';
import ArcadeDemo from '../../components/Shared/ArcadeDemo';
import Icon, { IconType } from '../Shared/Icon';
import Image from 'next/image';
import Modal from '../Modals/BaseModal';
import Person1 from '../../assets/images/seo/person-images/person1.svg';
import Person2 from '../../assets/images/seo/person-images/person2.svg';
import Person3 from '../../assets/images/seo/person-images/person3.svg';
import Person4 from '../../assets/images/seo/person-images/person4.svg';
import Person5 from '../../assets/images/seo/person-images/person5.svg';
import Person6 from '../../assets/images/seo/person-images/person6.svg';
import Person7 from '../../assets/images/seo/person-images/person7.svg';
import React, { useState } from 'react';
import router from 'next/router';

const BusinessSection = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const Persons = [
    {
      image: Person1,
      name: 'Authors'
    },
    {
      image: Person2,
      name: 'Career Coach'
    },
    {
      image: Person3,
      name: 'Content Marketer'
    },
    {
      image: Person4,
      name: 'Business Owner'
    },
    {
      image: Person5,
      name: 'Content Marketer'
    },
    {
      image: Person6,
      name: 'Consultant'
    },
    {
      image: Person7,
      name: 'Freelancer'
    }
  ];
  return (
    <div className="relative flex flex-col justify-center bg-grey-50 px-3 py-[64px]">
      <Modal
        isVisible={isModalOpen}
        className="flex flex-col"
        closeModal={() => setIsModalOpen(false)}
      >
        <button
          className="absolute right-4 top-4 z-10 cursor-pointer"
          onClick={() => setIsModalOpen(false)}
        >
          <Icon type={IconType.CrossLight} size={24} />
        </button>
        <ArcadeDemo className="lg:h-[500px] xl:h-[500px]" />
      </Modal>
      <h1 className="mx-auto mb-2 text-center text-[30px] font-500 leading-[44px] tracking-[-0.72px] sm:text-[32px] md:text-[34px] lg:text-[36px]">
        Designed for small businesses
      </h1>
      <h2 className="mx-auto mb-4 text-center text-base font-book leading-[32px] text-grey-600 sm:text-[20px] lg:text-[20px]">
        Create high quality content that actually grows your business
      </h2>
      <div className="mx-auto mt-14 grid max-w-[1088px] grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-7">
        {Persons.map((person, index) => (
          <div
            key={index}
            className={`relative mx-auto ${
              index % 2 === 0
                ? 'md:mt-[130px] lg:mt-[130px]'
                : 'md:mt-[8px] lg:-mt-[8px]'
            }`}
          >
            <Image
              className="h-[100px] w-[100px] rounded-full"
              src={person.image}
              alt={'footer image - try jupitrr for free'}
            />
            <div
              className={`relative bottom-[40px] left-[60px] flex inline-flex h-[48px] shadow-sm ${
                person.name === 'Content Marketer' ||
                person.name === 'Business Owner'
                  ? 'lg:w-[180px]'
                  : 'lg:w-[180px]'
              } items-center justify-start gap-4 rounded-lg border-2 bg-white px-3.5 py-2.5 text-sm leading-tight`}
            >
              <Icon
                type={'mi-check'}
                size={25}
                className="relative mb-[12px] h-[20px] w-[20px] font-bold text-blue-600"
              />
              <div className="flex h-5 shrink grow basis-0 items-center justify-start gap-2">
                <div className="w-[180px] shrink grow basis-0 text-md leading-tight text-[#1d2838] lg:w-[231px]">
                  {person?.name}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mx-4 mt-[24px] flex h-12 items-center justify-center space-x-2">
        <NewJPButton
          colorType="transparent"
          text={'Watch demo'}
          width={'fit'}
          size="md"
          onClick={() => setIsModalOpen(true)}
          className="justify-center border border-solid !border-gray-300 bg-white"
        />

        <NewJPButton
          text={'Try Jupitrr AI for free'}
          width={'fit'}
          size="md"
          className="justify-center border border-solid !border-gray-300"
          onClick={() => router.push('/create-slideshow')}
        />
      </div>
    </div>
  );
};

export default BusinessSection;
